<template>
  <app-page-layout :title="$t('hr.imprest.create_imprest')" icon="tw-hr-cost" @close="leavePage">
    <template #content>
      <validation-observer ref="form">
        <v-flex xs12>
          <validation-provider :name="$t('hr.imprest.amount')" rules="required|max:255" v-slot="{ errors }">
            <v-text-field
              v-model="imprest.requested.amount"
              :label="$t('hr.imprest.amount')"
              :loading="loading"
              :disabled="loading"
              color="tertiary"
              clearable
              type="number"
              :error-messages="errors"
            />
          </validation-provider>
        </v-flex>
        <v-flex xs12>
          <validation-provider :name="$t('hr.imprest.reason')" rules="required|max:512" v-slot="{ errors }">
            <v-text-field
              v-model="imprest.reason"
              :label="$t('hr.imprest.reason')"
              :loading="loading"
              :disabled="loading"
              color="tertiary"
              clearable
              :counter="512"
              :error-messages="errors"
            />
          </validation-provider>
        </v-flex>
        <v-flex xs12>
          <v-menu
            ref="menu"
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <validation-provider :name="$t('hr.imprest.period')" rules="required|max:512" v-slot="{ errors }">
                <v-text-field
                  v-model="imprest.period"
                  :label="$t('hr.imprest.period')"
                  :error-messages="errors"
                  readonly
                  v-on="on"
                />
              </validation-provider>
            </template>
            <v-date-picker
              :first-day-of-week="1"
              :locale="$store.state.auth.language"
              color="primary"
              v-model="imprest.period"
              @input="dateMenu = false"
            />
          </v-menu>
        </v-flex>
        <v-flex xs12>
          <travel-picker v-model="imprest.travel" />
        </v-flex>
        <v-flex xs12>
          <expense-management-picker v-model="imprest.expenseManagement" :employee="$store.state.auth.user.id" />
        </v-flex>
        <v-flex xs12>
          <validation-provider :name="$t('global.description')" rules="max:512" v-slot="{ errors }">
            <v-text-field
              v-model="imprest.description"
              :label="$t('global.description')"
              :loading="loading"
              :disabled="loading"
              color="tertiary"
              clearable
              :counter="512"
            />
          </validation-provider>
        </v-flex>
      </validation-observer>
    </template>
    <template #footer>
      <app-page-footer create>
        <template #right>
          <v-btn text @click="validateAndSave" :loading="loading">{{ $t("buttons.save") }}</v-btn>
        </template>
      </app-page-footer>
    </template>
  </app-page-layout>
</template>

<script>
  import Imprest from "../model/Imprest";

  export default {
    name: "ExpenseCreate",
    components: {
      "currency-picker": () => import("@/components/selectpickers/currency-picker"),
      TravelPicker: () => import("@/components/selectpickers/TravelPicker"),
      ExpenseManagementPicker: () => import("@/components/selectpickers/ExpenseManagementPicker"),
      AppPageFooter: () => import("@/layouts/app-page-footer")
    },
    data: () => ({
      loading: false,
      dateMenu: false,
      imprest: new Imprest(),
      onFileSend: () => {}
    }),
    methods: {
      leavePage() {
        this.$router.replace({ name: "imprests" });
      },
      validateAndSave() {
        this.$refs.form.validate().then(valid => {
          if (valid) {
            this.loading = true;
            this.$api.imprestService
              .save(this.imprest)
              .then(({ data }) => {
                if (!data.error) {
                  this.$emit("success:save");
                  this.leavePage();
                }
              })
              .catch(e => {
                this.$helpers.showNotification(e.message);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
      }
    }
  };
</script>

<style scoped></style>
